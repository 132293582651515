body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button {
  min-width: max-content;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* 스크롤바 없애기 */
::-webkit-scrollbar {
  width: 0px; /* remove scrollbar space */
  height: 0px;
  background: transparent; /* optional: just make scrollbar invisible */
}
::-webkit-scrollbar-thumb {
  background: transparent;
}

/* board content 줄바꿈 */
pre {
  white-space: pre-wrap;
  word-wrap: break-word;
  font-family: inherit;
  font-size: inherit;
}

.cursor-pointer {
  cursor: pointer;
}

.top-links-position-middle {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.w-max-content {
  width: max-content;
}

/* Line Number CSS */
/* for block of numbers */
.hljs-ln-numbers {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  /*-khtml-user-select: none;*/
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-align: center;
  color: #ccc;
  border-right: 1px solid #ccc;
  vertical-align: top;
  width: 25px;
}

/* your custom style here */
.hljs-ln td.hljs-ln-code {
  padding-left: 5px;
}

/*
스크롤 있음
*/
.one-line-sentence {
  white-space: nowrap;
  /* overflow: hidden; */
  text-overflow: ellipsis;
}

/*
스크롤 없음
*/
.one-line-sentence-cut {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* table tbody tr th/td 자동으로 한줄줄임 방식, 세로 중앙 정렬 */
table > tbody > tr > th,
table > tbody > tr > td,
ul > li,
ol > li {
  white-space: nowrap;
  text-overflow: ellipsis;
}
table > tbody > tr > th,
table > tbody > tr > td {
  overflow: hidden;
  vertical-align: middle !important;
}

.active-bg-text {
  background-color: antiquewhite;
  /* font-weight: bold; */
}

#loading-image {
  background: transparent url(../public/img/loading.gif) no-repeat center center;
  height: 100px;
  width: 100px;
  position: fixed;
  top: 50vh;
  left: 50vw;
  transform: translate(-50%, -50%);
  z-index: 9999992;
}

h3 {
  margin-top: 2.5rem;
  margin-bottom: 1rem;
}

/* #root
  > div.container
  > div.row
  > div
  > div
  > div.border.shadow.p-4.mb-4.rounded
  div
  > h3 {
  margin-top: 2.5rem;
  margin-bottom: 1rem;
} */

.page-title {
  font-size: 2rem;
}
