/* input 내의 placeholder */
input::placeholder {
  color: #a09e9e !important;
}
input::-webkit-input-placeholder {
  color: #a09e9e !important;
}
input:-ms-input-placeholder {
  color: #a09e9e !important;
}

textarea::placeholder {
  color: #a09e9e !important;
}
textarea::-webkit-input-placeholder {
  color: #a09e9e !important;
}
textarea:-ms-input-placeholder {
  color: #a09e9e !important;
}

mark {
  padding: 0;
}

/* th는 기본적으로 중앙정렬 */
table th,
table td {
  text-align: center;
  line-height: 200%;
}

/*

table에 style={{ tableLayout: "fixed", width: "100%" }} 필수 설정!
*/
#root table tr > th,
#root table tr > td {
  /* width: 100%;  */
  /* 가로 길이 고정*/
  text-overflow: ellipsis; /* 생략 처리 ( ... )*/
  overflow: hidden;
  white-space: nowrap; /* 줄바꿈 하지 않고 잘림*/
}

/* tr 배경색 효과를 위한 조치 */
.table > :not(caption) > * > * {
  background-color: transparent;
}

/* 이하 홈 */

/* 이하 시장지표 */
#root > div.container > div.row > div > div > div > div > div.card-body {
  margin-top: 0.5rem;
}

#root
  > div.container
  > div.row
  > div
  > div
  > div
  > div
  > div.card-body
  > a
  > div {
  margin-bottom: 0.5rem;
}

.bg-selected {
  background-color: #eceffa;
}

.links {
  border: 1px solid #e9ecef;
  border-radius: 0.25rem;
  padding: 1rem;
  /* padding-bottom: 0; */
  margin-bottom: 1rem;
}
.links .linkTitle {
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 1rem;
}
.links button {
  border: 1px solid #e9ecef;
  border-radius: 0.25rem;
  margin-bottom: 1rem;
}
.links button:hover {
  background-color: #e9ecef;
}
.links .btn-google-search {
  border: 1px solid rgba(113, 178, 168, 0.214);
}
.links .btn-google-search:hover {
  background-color: rgba(113, 178, 168, 0.214);
}

.select-btn button,
.select-btn a {
  /* padding: 0.2rem; */
  font-size: normal;
}