html {
  overflow: scroll;
  overflow-x: hidden;
  /*overflow: -moz-scrollbars-none;*/ /*파이어폭스 스크롤바 없애는것, 작동은 되는데 표준은 아닌것 같음*/
}

::-webkit-scrollbar {
  width: 0px; /* remove scrollbar space */
  background: transparent; /* optional: just make scrollbar invisible */
}
/* optional: show position indicator in red */
::-webkit-scrollbar-thumb {
  background: #ff0000;
}

a {
  text-decoration: none;
  color: #666;
}

a:hover {
  color: orange;
}
/**********************************************************************/
/* header */
/**********************************************************************/
header {
  position: fixed;
  top: 0;
  right: 0;
  width: 320px;
  height: 100%;
  -ms-overflow-style: none;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: #cc8598;
  /* display: none; */
}

header .container {
  /*header의 여백설정*/
  padding: 20px 10px 10px 10px;
}

header #dvGreeting {
  text-align: right;
  margin-bottom: 30px;
}

header h1 {
  margin: 0 0 26px 0;
}

header h1 a {
  font-size: 30px;
  color: #fff;
  font-weight: 800; /*구글 나눔고딕 웹서비스 기준*/
  text-transform: uppercase;
  text-decoration: none;
}

header #pGuide {
  font-size: 14px;
  margin-bottom: 12px;
}

header p {
  color: #efe5e5;
}

header p span {
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
}

header #pName {
  font-size: 14px;
  margin-top: 15px;
}

header #pEmail,
header #pKakao {
  font-size: 14px;
  margin-top: 13px;
}

/* header #dvCareer {
} */

header #dvCareer .dvCareerTitle {
  margin-bottom: 20px;
  font-weight: 700;
}

header #dvCareer .dvCareerTitle h2 {
  background-image: url(https://dooneenetstorage.blob.core.windows.net/images/check.png);
  background-repeat: no-repeat;
  background-position: 0 3px; /*아이콘 정렬*/
  padding-left: 25px;
  font-size: 18px;
  color: #f6d5de;
  margin-bottom: 13px;
}

.dvCareerTitle ul {
  list-style: circle; /*속 빈 원형*/
  padding-left: 30px;
}

header #dvCareer .dvCareerTitle ul li {
  line-height: 24px;
  font-size: 14px;
}

/**********************************************************************/
/* article */
/**********************************************************************/
article {
  width: calc(
    100% - 320px
  ); /*브라우저 너비에 따라 자동으로 article 부분 너비 설정*/
  text-align: center;
  /* display: none; */
}

article .container {
  padding: 20px;
  padding-bottom: 0px;
}

article .container ul li.liArticle {
  display: inline-block; /* inline : 가로속성을 못줌*/
  width: 200px;
  /* height: 160px; */
  /* height: auto; */
  /* text-align: left; */
  text-align: center;
  margin: 10px;
  /* margin-bottom: 90px; */
  padding: 1rem;
  /* border: "1px solid #999"; */
  /* vertical-align: "top"; */
  border-radius: 10px;
}

article .container ul li.liArticle .dvArticleListItem {
  /*프로젝트 이미지*/
  width: 100%;
  height: 100%;
  background-size: 100%; /*세로여백은 안보임*/
  border: 1px solid #9b9aa6;
  background-repeat: no-repeat;
  background-position-y: center;
  background-color: #fff;
}

article .container ul li.liArticle .dvArticleListItem a {
  display: block;
  width: 100%;
  height: 100%;
  font-weight: 700;
}

article .container ul li.liArticle .dvArtileText {
  padding: 10px 0 10px 0;
  font-weight: 700;
  font-size: 13px;
  /*width: 120px;*/ /*긴글 자동으로 줄이기1*/
  height: 50px;
  color: #333;
  white-space: nowrap; /*긴글 자동으로 줄이기2 : 공간을 넘어선 부분 내리지 않겠다.*/
  overflow: hidden; /*긴글 자동으로 줄이기3*/
  text-overflow: ellipsis; /*긴글 자동으로 줄이기4*/
}

article .container ul li.liArticle .dvArtileText li {
  padding: 0 0 8px 0;
}

article .container ul li.liArticle div ul li.liProject {
  font-size: 14px;
  color: #c4c3cb;
  margin-bottom: 15px;
}

article .container ul li.liArticle div ul li.liWebsiteUrl a {
  font-size: 14px;
  color: #c4c3cb;
  text-decoration: none;
  font-weight: 700;
}

article .container ul li.liArticle .dvArticleListItem:hover,
article .container ul li.liArticle div ul li.liWebsiteUrl a:hover {
  opacity: 0.8;
}

/* article .container h2 {
  font-size: 24px;
  color: #666;
  font-weight: 800;
  margin-bottom: 20px;
} */

article .container h2 span {
  color: #808080;
  display: block;
  font-size: 22px;
  font-family: "맑은 고딕";
}

article div dl {
  width: 600px;
  margin-left: auto; /*좌*/
  margin-right: auto; /*우 자동 가운데 정렬*/
}

article div dl dt,
article div dl dd {
  display: inline-block;
  font-size: 14px;
  border-bottom: 1px dotted #c4bfbf;
  line-height: 30px;
}

article div dl dt {
  width: 24%; /* 더 좋은 아이디어?? */
  font-weight: 700;
  text-align: right;
  /*상하 중앙정렬?? */
  margin-right: 5px; /* dt와 dd 사이 하단에 라인 붙이는법?? */
}

/* 마지막 dt만 100% 하고싶을때?? */
/*article div dl dt dd:last-child {
          width: 100%;
          background-color:red;
      }*/

article div dl dd {
  width: 74%; /* 더 좋은 아이디어?? */
  text-align: left;
}

article div dl dt:nth-last-child(-n + 2),
article div dl dd:last-child {
  border-bottom: 0px dotted #c4bfbf; /* 마지막 라인 없애기 */
}

article div dl dd.twoPart {
  /* 더 좋은 아이디어?? */
  width: 24%;
}

article div dl dt:nth-last-child(-n + 2) {
  text-align: center;
  padding-top: 10px;
  width: 100%;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

article div dl dd:last-child {
  width: 100%;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

article div dl dd#lblFiles p img {
  max-width: 100%; /* 원본해상도로 보이게 하고싶을때?? 이미지가 500px 안될경우?? */
  /*height:100%;*/
  background-size: 100%; /*세로여백은 안보임*/
  border: 1px solid #9b9aa6;
  margin-top: 10px;
  padding: 10px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

article .container .viewMore {
  padding: 20px;
}

article .container .viewMore #btnViewMore {
  background-color: #f6d5de;
  border-radius: 5px;
}
/*dl dt, dl dd{
          display:inline-block;
          height:30px;
          line-height:30px;
      }*/

/* .imgWidth {
  border: 1px solid #eee;
  max-width: 80%;
  width: expression(this.width > 600 ? 600: true);
  height: auto;
} */
/**********************************************************************/
/* footer */
/**********************************************************************/
footer {
  border-top: 1px dotted #c4c3cb;
  /* padding: 10px; */
  color: #808080;
  /* font-size: 9pt; */
  /* font-style: italic; */
  /* display: none; */
}
.wrapFooter {
  padding-right: 320px; /* 따움표 붙이면 안됨! "320px" */
}
/**********************************************************************/
/* @media */
/**********************************************************************/
/* 1200px 이하 */
@media (max-width: 1200px) {
  article .container ul li.liArticle {
    width: 230px;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
}

/* 1000px 이하 */
@media (max-width: 1000px) {
  article .container ul li.liArticle {
    width: 230px;
    height: auto !important;
  }

  article div dl {
    width: 100%;
  }

  article div dl dt {
    width: 34%;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  article div dl dd,
  article div dl dd.twoPart {
    /* 더 좋은 아이디어?? */
    width: 59%;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
}
/* 850px 이하 */
@media (max-width: 890px) {
  article .container ul li.liArticle {
    width: 270px;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
}
/* 768px 이하 : 아이패드 */
@media (max-width: 768px) {
  article div dl dt {
    width: 39%;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  article div dl dd,
  article div dl dd.twoPart {
    /* 더 좋은 아이디어?? */
    width: 49%;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
}
/* 700px 이하 */
@media (max-width: 700px) {
  article .container ul li.liArticle {
    width: 230px;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  article div dl dt {
    width: 34%;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  article div dl dd,
  article div dl dd.twoPart {
    /* 더 좋은 아이디어?? */
    width: 54%;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  /* article .container h2 {
    font-size: 20px;
  } */

  article div dl dt,
  article div dl dd {
    font-size: 13px;
  }
}
/* 650px 이하 */
@media (max-width: 650px) {
  .wrapFooter {
    padding-right: 0; /* 따움표 붙이면 안됨! "320px" */
  }

  header,
  article {
    position: relative;
    width: 100%;
  }

  header #dvGreeting {
    text-align: center;
  }

  article .container ul li.liArticle {
    width: 300px;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  #dvGreeting img {
    width: 300px;
  }

  /* article .container h2 {
    font-size: 22px;
  } */

  article div dl dt,
  article div dl dd {
    font-size: 14px;
  }

  header {
    background-color: #c18297;
  }

  article {
    background-color: #d6e7ef;
  }

  #mainArticle > div > ul > li {
    background-color: #fff;
  }

  article .container ul li.liArticle div ul li.liProject,
  article .container ul li.liArticle div ul li.liWebsiteUrl a {
    color: #666;
  }

  footer {
    /* background-color: #f4f2df; */
    padding: 20px;
    border: 0;
  }
}
/* 540px 이하 */
@media (max-width: 540px) {
  article .container ul li.liArticle {
    width: 280px;
  }
}
/* 460px 이하 */
@media (max-width: 460px) {
  #mainArticle > div > ul > li {
    background-color: #fff;
  }
}
/* 450px 이하 : 하단 프로젝트가 붙는 넓이 */
@media (max-width: 450px) {
  article .container ul li.liArticle {
    width: 270px;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  article .container ul li.liArticle {
    margin: 0px;
    margin-bottom: 20px;
  }

  article div dl dt {
    width: 30%;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  article div dl dd,
  article div dl dd.twoPart {
    /* 더 좋은 아이디어?? */
    width: 58%;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
}
/* 360px 이하 */
@media (max-width: 360px) {
  /* article .container h2 {
    font-size: 18px;
  } */

  article div dl dt,
  article div dl dd {
    font-size: 13px;
  }
}
/* 320px 이하 : 아이폰 */
@media (max-width: 320px) {
}
